<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">ZP - CEO's</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
        </div>
    </div>
    <div class="custom-ultima-datatable">
            <DataTable :value="zpceoList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" paginator :totalRecords="totalRecords" :rows="30" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading" >
            <template #empty>No Records found.</template>
            <template #loading>Loading data. Please wait...</template>
            <Column field="id" header="Sr." headerStyle="width: 5%" bodyStyle="width: 5%">
                <template #body="{ data }">
                    {{ data.id + 1 }}
                </template>
            </Column>
            <Column field="Name" header="ZP - CEO's Name" headerStyle="width: 100%" bodyStyle="width: 100%">
                <template #body="{ data }">
                    <div>{{ data.label ? data.label : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="Head Quarter" headerStyle="width: 55%" bodyStyle="width: 55%">
                <template #body="{ data }">
                    <div>{{ data.sublabel ? data.sublabel : "N/A" }}</div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
    <script>

export default {
    data() {
        return {
            zpceoList: [
                {
                    id: 0,
                    sublabel: "North Goa",
                    label: "Shri Ajay Gaude",
                },
                {
                    id: 1,
                    sublabel: "South Goa",
                    label: "Shri Rohit Ashok Kadam",
                },
                
            ],
            totalRecords: 2,
        };
    },
};
</script>